var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"dialog",attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{attrs:{"id":"settings","data-testid":"settings"}},[_c('TopBar',{on:{"done":_vm.onClickDone}}),_c('v-container',{staticClass:"inner-container"},[_c('v-list',{attrs:{"two-line":"","subheader":""}},[_c('v-subheader',[_vm._v("Scanning")]),_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"data-testid":"scan-type-sel","label":"Verify","items":_vm.scanTypes,"outlined":"","hint":"Set the type of verification you want to do","persistent-hint":""},model:{value:(_vm.selectedScanType),callback:function ($$v) {_vm.selectedScanType=$$v},expression:"selectedScanType"}})],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"data-testid":"input-type-sel","label":"Input From","items":_vm.inputTypes,"outlined":"","hint":"Set the input type used for scanning","persistent-hint":""},model:{value:(_vm.selectedInputType),callback:function ($$v) {_vm.selectedInputType=$$v},expression:"selectedInputType"}})],1)],1),(_vm.showGeneralSection)?_c('v-subheader',[_vm._v("General")]):_vm._e(),(_vm.showHidePersonalInfoSetting)?_c('v-list-item',{attrs:{"input-value":_vm.hidePassPersonalInfo,"three-line":""},on:{"click":_vm.onClickHidePassPersonalInfo},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"data-testid":"hide-pass-info-cb"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Hide pass personal info")]),_c('v-list-item-subtitle',[_vm._v(" Hides all personal information of a scanned pass and only shows the state and title of the pass (red or green) ")])],1)]}}],null,false,3983681850)}):_vm._e(),(_vm.showTaggingSection)?_c('v-subheader',[_vm._v("Tagging")]):_vm._e(),(_vm.showTagEventsSetting)?_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"data-testid":"event-tag-sel","label":"Events","items":_vm.tagEvents,"outlined":"","hint":"Tag all scans with the selected event","no-data-text":"No upcomming events","persistent-hint":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,2816812636),model:{value:(_vm.selectedTagEvent),callback:function ($$v) {_vm.selectedTagEvent=$$v},expression:"selectedTagEvent"}})],1)],1):_vm._e(),(_vm.showTagEventLocationsSetting)?_c('v-list-item',[_c('v-list-item-content',[_c('v-select',{attrs:{"data-testid":"event-location-sel","label":"Scan Location (optional)","items":_vm.selectedTagEvent.scanLocations,"outlined":"","hint":"The location you'll be scanning from at the selected event.","no-data-text":"No locations for this event","persistent-hint":"","clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,2816812636),model:{value:(_vm.selectedTagEventLocation),callback:function ($$v) {_vm.selectedTagEventLocation=$$v},expression:"selectedTagEventLocation"}})],1)],1):_vm._e(),_c('v-subheader',[_vm._v("Account")]),_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"color":"grey"}},[(_vm.userImage)?_c('img',{attrs:{"src":_vm.userImage,"alt":"User Image"}}):_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-account-circle-outline")])],1),_c('v-list-item-content',[(_vm.userName)?_c('v-list-item-title',[_vm._v(_vm._s(_vm.userName))]):_c('v-list-item-title',[_vm._v(_vm._s(_vm.email))]),(_vm.userName)?_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.email))]):_vm._e()],1)],1),_c('LogoutListItem',{on:{"clickLogout":_vm.onClickLogout}})],1),_c('div',{staticClass:"version"},[_vm._v("Version: "+_vm._s(_vm.version))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }